
import request from "../../Utils/curl"
import Utility from "../../Utils/utility"
let OrgSaas = {
  /**
   * orgSaasStudentDashboardList
   */
  async orgSaasStudentDashboardList (context, orgSaasObj) {
    Utility.showLoader = true
    Utility.loadingMsg = "Please wait...."
    try {
      let postData = new FormData()

      if (orgSaasObj.filter && Object.keys(orgSaasObj.filter).length > 0) {
        postData.append("filter", JSON.stringify(orgSaasObj.filter))
      }

      if (orgSaasObj.aff_entity_id) {
        postData.append("aff_entity_id", orgSaasObj.aff_entity_id)
      }
      return await request.curl(context, "orgsaas_student_dashboard_list", postData)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at orgSaasStudentDashboardList() and Exception:", err.message)
    }
    finally {
      Utility.showLoader = false
    }
  },
  /**
   * orgSaasAffCodeAnalyticsGet
   */
  async orgSaasAffCodeAnalyticsGet (context, orgSaasObj) {
    try {
      let postData = new FormData()

      if (orgSaasObj.aff_entity_id) {
        postData.append("aff_entity_id", orgSaasObj.aff_entity_id)
      }
      return await request.curl(context, "orgsaas_affiliate_code_analytics_get", postData)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at orgSaasStudentDashboardList() and Exception:", err.message)
    }
  },

  /**
   * orgSaasOrganisationEdit
   */
  async orgSaasOrganisationEdit (context, orgSaasOrgObj) {
    try {
      let postData = new FormData()

      for (let key in orgSaasOrgObj) {
        if (typeof orgSaasOrgObj[key] == "number") {
          postData.append(key, orgSaasOrgObj[key])
        }
        else if (orgSaasOrgObj[key]) {
          postData.append(key, orgSaasOrgObj[key])
        }
      }
      return await request.curl(context, "orgsaas_organisation_edit", postData)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at orgSaasOrganisationEdit() and Exception:", err.message)
    }
  },

  /**
   * orgSaasOrganisationView
   */
  async orgSaasOrganisationView (context, orgSaasId) {
    try {
      let postData = new FormData()
      postData.append("org_id", orgSaasId)

      return await request.curl(context, "orgsaas_organisation_view", postData)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at orgSaasOrganisationView() and Exception:", err.message)
    }
  },

  /**
   * orgSaasAffiliateList
   */
  async orgSaasAffiliateList (context, orgSaasObj) {
    try {
      let postData = new FormData()

      for (let key in orgSaasObj) {
        if (typeof orgSaasObj[key] == "number") {
          postData.append(key, orgSaasObj[key])
        }
        else if (orgSaasObj[key]) {
          postData.append(key, orgSaasObj[key])
        }
      }

      return await request.curl(context, "orgsaas_affiliate_list", postData)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at orgSaasAffiliateList() and Exception:", err.message)
    }
  },

  /**
   * orgsaasValidateOrgIdentifier
   */
  async orgsaasValidateOrgIdentifier (context, orgSaasObj) {
    try {
      let postData = new FormData()

      for (let key in orgSaasObj) {
        if (typeof orgSaasObj[key] == "number") {
          postData.append(key, orgSaasObj[key])
        }
        else if (orgSaasObj[key]) {
          postData.append(key, orgSaasObj[key])
        }
      }

      return await request.curl(context, "orgsaas_validate_org_identifier", postData)
        .then(async response => {
          return response
        })
    }
    catch (err) {
      console.error("Exception occurred at orgsaasValidateOrgIdentifier() and Exception:", err.message)
    }
  }
}

export {
  OrgSaas
}
